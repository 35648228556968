import React from 'react';
import { Grid } from '@material-ui/core';
import { envUrl } from '../configs/MetalConfig';
import CardBody from './card-body/card-body';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer style={{ background: '#303030' }}>
      {/* <div className="call-out">
        <div className="">This is a callout!</div>
      </div> */}
      <div
        className=""
        style={{ maxWidth: 1400, margin: 'auto', padding: '80px 30px' }}
      >
        <Grid container spacing={8} alignItems="flex-start">
          <Grid item xs={12} lg={4}>
            <img
              alt="Universal Metals Logo"
              style={{
                maxHeight: 67,
                width: 'auto',
                background: 'white',
                padding: '5px 20px',
                borderRadius: 4,
                marginBottom: 10,
              }}
              src={`${envUrl}wp-content/uploads/2017/07/umnewlogonew.png`}
            />
            <h3 style={{ color: 'white', margin: 0 }}>Universal Metals</h3>
            <p style={{ color: 'white', margin: '10px 0 20px', fontSize: 18 }}>
              We are a next generation supplier of hard-metals to the aerospace
              industry as well as other critical application industries. We are
              committed to setting industry leading standards in all measures of
              business performance and customer service.
            </p>
            <img
              alt="AS9100 Certified"
              style={{ width: '100%' }}
              src={`${envUrl}wp-content/uploads/2017/07/as9100.jpg`}
            />
            <img src="https://usfcr.com/assets/img/verified-vendor-seal-2025-sm.png" 
              alt="USFCR Verified Vendor" 
              title="US Federal Contractor Registration System for Award Management Verified Vendor Seal"
            />
          </Grid>
          <Grid container item xs={12} lg={8} spacing={4} alignItems="flex-start">
            <Grid item xs={12} md={6}>
              <CardBody title="Universal Metals Corona">
                Address: <a href="https://maps.app.goo.gl/VL2iyLud6u8b9VD88" target="_blank" style={{ fontSize: '18px' }}>1020 Railroad St. Corona, CA 92882</a>
                <br />
                Office Phone: <a href="tel:+19512713950" style={{ fontSize: '18px' }}>(951) 271-3950</a> <br />
                Toll free: <a href="tel:+18008626159" style={{ fontSize: '18px' }}>(800) 862-6159</a>
                <br />
                Fax: (951) 271-3940
              </CardBody>
            </Grid>
            <Grid item xs={12} md={6}>
              <h2 className='footer-resources-heading' style={{ color: 'white', margin: '0 0 20px 60px' }}>Resources</h2>
              <ul className='footer-resources'>
                <li><a href="/contact/">Contact Us</a></li>
                <li><a href="/inventory/">Inventory</a></li>
                <li><a href="/services/">Services</a></li>
                <li><a href="/tools/conversion/">Tools</a></li>
                <li><a href="/about-us-2/">About Us</a></li>
                <li><a href="/quality/">Quality</a></li>
                <li><a href="https://admin.universalmetals.com/wp-content/uploads/2019/08/UM_LineCard.pdf" target="blank">Product Line Card</a></li>
                <li><a href="/glossary/">Metals Glossary</a></li>
                <li><a href="/sitemap/">Sitemap</a></li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div
        className="bottom-bar"
        style={{
          background: 'rgb(56, 48, 103)',
          padding: '8px 4px',
          color: 'white',
          textAlign: 'center',
        }}
      >
      
        {currentYear} &copy; Universal Metals. All Rights Reserved.
      </div>
    </footer>
  );
};

export default Footer;
